<template>
  <div>
    <base-header class="pb-6">
      <b-row aling-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <b-row>
        <b-col>
          <b-card no-body>
            <b-card-header class="border-0">
              <h3 class="mb-0">Generate a campaign with AI</h3>
            </b-card-header>
            <b-card-body>
              <b-row>
                <b-col class="display-4">

                </b-col>
              </b-row>
              <b-row class="mt-3">
                <b-col cols="12">
                  <base-input name="Campaign Name" v-model="promptText" rules="required"
                              placeholder="Message Qulerts!!">
                  </base-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6">
                  <el-select placeholder="Example Prompts" v-model="promptText" filterable class="no-border w-100">
                    <el-option v-for="o in examplePrompts" :key="o" :label="o" :value="o"/>
                  </el-select>
                </b-col>
                <b-col class="justify-content-end text-right" offset="3" cols="3">
                  <b-button variant="primary">Generate Campaign</b-button>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import memberService from '../../services/members';
import SubscriberGrowth from '../../components/subscribers/subscriber-growth';
import { FilterQuery } from '@/models/filterQuery';
import { beforeNowTillStartSecond } from '@/utils/date';
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
import Stats from '../../components/qulerts/widgets/stats/index';
import ChartAppActions from '../../components/qulerts/charts/appActions/index';
import ChartAppLaunchDay from '../../components/qulerts/charts/appLaunchDay/index';
import ChartAppLaunchHour from '../../components/qulerts/charts/appLaunchHour/index';
import { Select, Option } from 'element-ui';


export default {
  name: 'homePage',
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    RouteBreadCrumb,
  },
  data: () => ({
    promptText: '',
    examplePrompts: [
      'Create a flash sales campaign for the weekend',
      'Send a push notification to all subscribers',
      'Prepare a campaign for the upcoming holiday season',
      'Analyse the audience and create a campaign for likely churners',
    ],
    subscribersCount: 0,
    activeSubscribersCount: 0,
    pushTokensCount: 0,
    emailCount: 0,
    phoneCount: 0,
    lastDayNewSubscriberCount: { count: 0 },
  }),
  methods: {
    async getStatistics() {
      const activeCount = await memberService.count(
        new FilterQuery()
          .addRange('fs', {
            gte: beforeNowTillStartSecond({ days: 30 }),
          })
          .build(),
      );
      const allCount = await memberService.count(new FilterQuery().build());
      const pushCount = await memberService.count(new FilterQuery()
        .exists('tk')
        .build());
      const emailsCount = await memberService.count(new FilterQuery()
        .exists('em')
        .build());
      const smsCount = await memberService.count(new FilterQuery()
        .exists('ph')
        .build());
      this.$set(this, 'subscribersCount', allCount.count);
      this.$set(this, 'activeSubscribersCount', activeCount.count);
      this.$set(this, 'pushTokensCount', pushCount.count);
      this.$set(this, 'emailCount', emailsCount.count);
      this.$set(this, 'phoneCount', smsCount.count);
    },
  },
};
</script>
